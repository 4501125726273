body.e-control {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", 
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", 
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", 
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
}

.header-and-wrapper {
  width: 100%;
  height: 7vh;
  z-index: 200;
}

.flex-grow {
  flex: 2;
}

.main-wrapper {
  height: 100vh;
  width: 100%;
  z-index: 500;
}

.scroll-content {
  height: calc(100vh - 49px);
  overflow-y: auto;
}


$theme-colors: (
  "primary": #8f00ff,
  "secondary": #e8ccff,
  "third": #00ff00,
  "fourth": #491777,
  "orange": #ff3503,
  "danger": #ff0041,
  "gray-simple": #d4ced1,
);

// to remove connector dots
/*.e-connectorpoint-left {
  display: none !important;
}

.e-connectorpoint-right {
  display: none !important;
}*/

.e-gantt-chart .e-taskbar-main-container:has(.e-gantt-parent-taskbar) .e-left-connectorpoint-outer-div,
.e-gantt-chart .e-taskbar-main-container:has(.e-gantt-parent-taskbar) .e-right-connectorpoint-outer-div,
.e-gantt-chart .e-taskbar-main-container:has(.e-gantt-parent-milestone) .e-left-connectorpoint-outer-div,
.e-gantt-chart .e-taskbar-main-container:has(.e-gantt-parent-milestone) .e-right-connectorpoint-outer-div {
  display: none !important;
}

textarea:focus,
select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="select"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  outline: 0 none;
}

.b-btn-filter {
  display: inline-flex;
  align-self: center;
  align-items: stretch;
  height: 22px;
  padding-top: 9px;
  padding: auto;
  margin-left: 60rem;
}

.b-tbar-element {
  height: 25px;
  align-self: center;
}


$enable-gradients: (true);

@import "node_modules/bootstrap/scss/bootstrap";


.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-group-item.e-item-focus {
  background-color: transparent !important;
  box-shadow: none !important;
}

.e-popup .e-checkbox-wrapper .e-frame.e-check {
  border-color: initial !important;
}

.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item .e-tab-text {
  font-weight: 500 !important;
  font-family: "Segoe UI";
}

.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item.e-active .e-tab-text {
  color: #491777 !important;
}

.e-tab .e-tab-header .e-toolbar-items .e-toolbar-item:not(.e-active) .e-tab-text {
  color: #495057 !important;
}

.e-splitter.e-splitter-vertical .e-pane.e-pane-vertical > div {
  height: 100%;
}

.for-wide-icon {
  font-size: 13px !important;
  width: 15px !important;
}

.e-pager div.e-icons.e-pager-default {
  color: #491777 !important;
}

.e-pager .e-currentitem.e-numericitem.e-focused {
  background: #491777 !important;
  box-shadow: 0 0 0 1px #491777 inset !important;
}

.e-pager .e-focused:not(.e-menu-item) {
  box-shadow: 0 0 0 1px #491777 inset !important;
}

.react-loading-skeleton.full-page-skeleton {
  line-height: unset !important;
  width: 100%;
  height: 100%;
}

#basic-gantt-view_dialog_dialog-header,
#team-tasks-view_dialog_dialog-header {
  border-radius: 3px 3px 0 0;
  background-color: var(--primary);
}

#basic-gantt-view_Tab .e-tab-header,
#team-tasks-view_Tab .e-tab-header {
  background-color: var(--secondary);
}

.e-diagram #diagram_editTextBoxDiv * {
  box-shadow: none;
  height: 28px !important;
}

.movie-button:hover {
  border-color: var(--gray-dark) !important;
}

.movie-button:disabled {
  opacity: 0.75;
  cursor: default;
}

.e-control-wrapper.e-slider-container {
  height: 25px !important;
}

.e-control-wrapper.e-slider-container .e-slider .e-handle {
  background-color: var(--gray) !important;
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px rgb(73 23 119 / 25%);
}

.btn {
  white-space: nowrap;
}

.two-line-header {
  line-height: 1.25;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.three-line-header {
  line-height: 1.25;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.three-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.four-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.five-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wide-multiselect .e-multi-select-wrapper {
  input[type="text"] {
    font-size: 16px;
    font-weight: 400;
    height: 38px;
    min-height: 38px;
    text-indent: 12px;
  }

  .e-delim-values {
    line-height: 38px;
    padding: 0 12px;
  }

  .e-input-group-icon {
    height: 38px;
  }

  .e-chips-close.e-close-hooker {
    right: 24px !important;  
  }
}

.animate-copy-container {
  animation: copyAnimation 1.2s ease-in-out;
}

@keyframes copyAnimation {
  0% {
    border-color: transparent;
    background-color: transparent;
  }
  30% {
    border-color: #00C642;
    background-color: hsl(120, 100%, 93%);
  }
  70% {
    border-color: #00C642;
    background-color: hsl(120, 100%, 93%);
  }
  100% {
    border-color: transparent;
    background-color: transparent;
  }
}

.dragging-icon .e-grid {
  cursor: grab !important;
}

.visual-planning_overview-component {
  position: absolute;
  bottom: 30px;
  right: 330px;
  border: 1px solid gray;
  border-radius: 3px;
  background-color: whitesmoke !important;
  pointer-events: none;

  #overview_canvasoverviewhandle {
    display: none;
  }
}


.e-input-group.e-control-wrapper.syncfusion-form-control {
  padding: 0.25rem;

  .e-control {
    font-size: 1rem;
  }

  .e-spin-show,
  .e-nodata {
    display: none !important;
  }

  &.is-invalid {
    border-color: red !important;
  }
}

.e-input-group.e-control-wrapper .syncfusion-form-control.e-dropdownlist {
  font-size: 16px;
  padding-left: 0.75rem;
  color: #495057 !important;
}

.b-button label {
  margin-bottom: 0;
}

.b-gantttaskeditor-content .b-label {
  text-align: left;
}

.b-grid-header .b-sch-nonworkingtime-canvas .b-sch-timerange.b-sch-line,
.b-timeline-subgrid .b-sch-nonworkingtime-canvas .b-sch-line {
  display: none;
}

.e-badge.badge-fourth {
  color: #fff;
  background-color: #491777;
}

.error-boundary-circle-1 {
  position: absolute;
  background-color: #e8ccff;
  height: 160%;
  width: 100%;
  border-radius: 50%;
  top: -30%;
  right: -40%;
  z-index: -1;
  opacity: 0;
  animation: .9s ease-out .3s forwards opacityAnimation;
}

.error-boundary-circle-2 {
  position: absolute;
  background-color: #e8ccff99;
  height: 160%;
  width: 100%;
  border-radius: 50%;
  top: -30%;
  right: -25%;
  z-index: -2;
  opacity: 0;
  animation: .6s ease-out .15s forwards opacityAnimation;
}

.error-boundary-circle-3 {
  position: absolute;
  background-color: #e8ccff55;
  height: 160%;
  width: 100%;
  border-radius: 50%;
  top: -30%;
  right: -10%;
  z-index: -3;
  opacity: 0;
  animation: .3s ease-out 0s forwards opacityAnimation;
}

@keyframes opacityAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Fixes for syncfusion components

body .e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content.e-draggable, 
body .e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content.e-draggable, 
body .e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content.e-draggable, 
body .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-pvt-btn-content.e-draggable {
  height: auto;
}

body .e-pivotview .e-group-filters .e-pvt-btn-div .e-pivot-button .e-icons,
body .e-pivotview .e-group-rows .e-pvt-btn-div .e-pivot-button .e-icons,
body .e-pivotview .e-group-columns .e-pvt-btn-div .e-pivot-button .e-icons,
body .e-pivotview .e-group-values .e-pvt-btn-div .e-pivot-button .e-icons {
  height: 10px;
  width: 18px;
}

// workarounds for dimension resources in scenario gantt
// Different types of resources are marked by color classes according the RESOURCE_COLORS constant
#scenarioGantt.b-gantt .b-grid-body-container .b-grid-row .b-grid-cell, #program-gantt.b-gantt .b-grid-body-container .b-grid-row .b-grid-cell {
  &[data-column-id="resourceassignment"] {
    .b-resource-avatar.b-sch-green,
    .b-resource-avatar.b-sch-teal,
    .b-resource-avatar.b-sch-lime {
      display: none;
    }

    .b-overflow-count {
      display: none;
    }

    .b-overflow-img .b-resource-avatar {
      filter: none;
    }
  }

  &[data-column-id="dimensions"],
  &[data-column-id="nonlaborresourceassignment"] {
    padding: 0.4em;

    .b-react-portal-container {
      width: 100%;
      height: 100%;
      display: flex;

      .b-resource-avatar-container {
        width: 100%
      }
    }
  }
}

// Styles for resource assignment popup
// Different types of resources are marked by color classes according the RESOURCE_COLORS constant
.b-assignmentgrid {
  .b-grid-header-container .b-check-header-with-checkbox {
    user-select: none;
    pointer-events: none;
    opacity: 0;
  }

  .b-grid-subgrid .b-grid-row:has(.b-resource-avatar.b-sch-lime) {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }

  .b-grid-subgrid .b-grid-row:has(.b-resource-avatar.b-sch-lime),
  .b-grid-subgrid .b-grid-row:has(.b-resource-avatar.b-sch-green),
  .b-grid-subgrid .b-grid-row:has(.b-resource-avatar.b-sch-teal) {
    div[data-column="units"] {
      display: none;
    }
  }
}

#scenarioGantt .b-gantt-task,
#program-gantt .b-gantt-task,
#program-timeline .b-gantt-task,
#timeline-gantt .b-gantt-task,
#scheduler-for-gantt .b-sch-event,
#task-management-scheduler .b-sch-event,
#work-package-scheduler .b-sch-event,
#dashboard-scheduler .b-sch-event,
#task-focus-gantt .b-gantt-task {
  border-radius: var(--task-border-radius);
}

$drb-border-color: #ced4da;
$drb-text-color: #495057;
$drb-active-border-color: #c780ff;
$drb-active-text-color: #8a35d8;

.drb-custom-btn-group {
  display: flex;

  .custom-btn {
    background: #ffffff;
    border: 1px solid $drb-border-color;
    color: $drb-text-color;
    outline: none;
    height: 31px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: bold;
    transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
    white-space: nowrap;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:first-child:not(:last-child) {
      border-right-color: transparent;
    }

    &:not(:first-child):not(:last-child) {
      border-right-color: transparent;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      color: $drb-active-text-color;    
    }

    &.selected {
      border-color: $drb-active-border-color !important;
      color: $drb-active-text-color;   
    }

    &:active {  
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      border-color: $drb-active-border-color !important;
    } 

    &:disabled {
      opacity: 0.7;
      pointer-events: none;
      user-select: none;
    }

    &.custom-btn-large {
      height: 38px;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 16px;
    }
  }

  .e-date-range-wrapper {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;

    .e-daterangepicker {
      display: none !important;
    }

    .e-range-icon {
      border: 1px solid $drb-border-color !important;
      background: initial !important;

      &:hover {
        background: initial !important;
        color: $drb-active-text-color !important;
        border-color: $drb-border-color !important;
      }

      &.e-active {
        color: $drb-active-text-color !important;
        border-color: $drb-active-border-color !important;
      }

      &::before {
        content: "Custom" !important;
        font-weight: bold !important;
        font-family: "Segoe UI" !important;
        font-size: 14px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }

    &.selected .e-range-icon {
      color: $drb-active-text-color !important;
      border-color: $drb-active-border-color !important;
    }

    &.disabled {
      opacity: 0.7;
      pointer-events: none;
      user-select: none;
    }
  }
}

#holidaysDialog, 
#ExceptionsDialog {
  .e-dlg-content {
    padding: 0px;

    & > div {
      height: 100%;
    }
  }
}

.column-gap-5 {
  column-gap: 5px;  
}

.column-gap-15 {
  column-gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.b-ganttbase.b-gantt-critical-paths .b-gantt-task.b-critical {
  background-color: #db5643 !important;
}

.flex-1 {
  flex: 1;
}

#metrics_view.e-dashboardlayout.e-control .e-panel:not(.e-drag):hover,
#metrics_portfolio_dashboard_v3.e-dashboardlayout.e-control .e-panel:not(.e-drag):hover {
  cursor: auto;
}

.gantt-summary-children-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8em;
  height: 1.8em;
  background: var(--color-blue);
  color: #fff;
  border-radius: 0.5em;
  font-size: 0.8em;
  margin-inline-start: 2em;
  font-weight: bold;
}

.b-widget.b-timeline {
  flex: none !important;
  height: 16em !important;
}

.b-widget.b-timeline.small {
  height: 14em !important;
}

.b-widget.b-timeline.small .b-grid-panel-body {
  padding-top: 2em !important;
  padding-bottom: 3em !important;
}

.b-widget.b-timeline.small .b-sch-event-content {
  font-size: 11px !important;
}

.b-widget.b-timeline.small label::before {
  display: none !important;
}

.b-widget.b-timeline.large {
  height: 21em !important;
}

.b-widget.b-timeline.large .b-grid-panel-body {
  padding-top: 4em !important;
  padding-bottom: 6em !important;
}

#timeline-gantt #timeline-gantt-normalSubgrid .b-sch-canvas .b-gantt-task-wrap .b-sch-label, 
#program-timeline #program-timeline-normalSubgrid .b-sch-canvas .b-gantt-task-wrap .b-sch-label {
  margin-bottom: 0;
  display: flex;
}

#timeline-gantt.big-rollups #timeline-gantt-normalSubgrid .b-sch-canvas .b-gantt-task-parent, 
#program-timeline #program-timeline-normalSubgrid .b-sch-canvas .b-gantt-task-parent {
  .b-generated-parent {
    display: none;
  }

  .b-task-rollup-wrap {
    height: 0.95em;
    margin-top: 0.05em;
    position: absolute;
    bottom: 0;

    .b-task-rollup {
      opacity: 1;
    }
  }
}

.scope-library-card {
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  transition: all 0.1s;
  flex: 0 1 calc(33.33% - 20px);
  min-height: 102px;

  &:hover:not(.disabled) {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    background-color: #efebf3;
  }

  &.selected-card {
    border: 1px solid #491777;
    background-color: #efebf3;
  }

  &.small-shadow {
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.075);

    &:hover:not(.disabled) {
      box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    }
  }

  .card-title {
    color: #491777;
    font-size: 1.2rem;
  }

  .menu-button {
    width: 31.75px;
    min-width: 31.75px;
    height: 29px !important;
    background-color: transparent;
    box-shadow: none;
    border: 0 !important;
    color: #491777 !important;

    &:hover,
    &.e-active {
      background-color: #491777;
      color: white !important;
    }
  }

  .subtitle-icon {
    color: #491777;
    margin-right: 0.5rem;
  }

  .subtitle-text {
    color: #491777;
    font-weight: 500;
  }

  .alt-color {
    color: #fab618;
  }

  .library-tag {
    padding: 3px 10px;
    border-radius: 4px;
    font-size: 0.8rem;
  }
}

.syncfusion-multiselect-form .e-multiselect .e-multi-select-wrapper {
  min-height: 36px;
  font-size: 16px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  .e-chips {
    height: 32px;
    
    .e-chipcontent {
      font-size: 16px; 
     }
  }

  .e-searcher .e-dropdownbase {
    font-size: 16px;
    height: 36px;
    padding-left: 4px;
  }
}

#task-focus-gantt .b-indicator.b-deadline-date::before {
  font-family: 'Font Awesome 6 Free';
}