.loader-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #f7f7f7;
}

.spinner-container {
    position: absolute;
}

.loading-text {
    position: absolute;
    font-size: 1.1rem;
    font-weight: 500;
    color: #8f00ff;
    text-shadow: 0 0 10px rgb(225, 165, 255);
    user-select: none;
}